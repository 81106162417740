<template>
  <div style="margin-top: 10px;">
    <el-card class="box-card">
      <div class="text item">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="轮播图标题" prop="title">
            <el-input
              v-model.trim="ruleForm.title"
              style="width: 500px"
              placeholder="请输入轮播图标题内容"
            ></el-input>
          </el-form-item>
          <el-form-item prop="imgUrl">
            <el-upload
              name="image"
              :action="ImageUrl"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :limit="1"
              :file-list="fileList"
              :headers="headers"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
              :visible.sync="dialogVisible"
              :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="开始时间" required prop="startTime">
            <el-col :span="11">
              <div class="block">
                <el-date-picker
                  v-model="ruleForm.startTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  :picker-options="startDatePicker"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-col>
          </el-form-item>
          <el-form-item label="结束时间" required prop="endTime">
            <el-col :span="11">
              <div class="block">
                <el-date-picker
                  v-model="ruleForm.endTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  :picker-options="endDatePicker"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-col>
          </el-form-item>
          <el-form-item label="权重" prop="height">
            <el-input
              v-model="ruleForm.height"
              style="width: 500px"
              placeholder="请输入数值"
              maxlength="4"
            ></el-input>
          </el-form-item>
          <el-form-item label="单选"  prop="source">
              <el-radio-group @change="dx" v-model="radio">
                <el-radio label='1'>跳转链接</el-radio>
                <el-radio label='2'>商品id</el-radio>
              </el-radio-group>
            </el-form-item>
          <el-form-item v-show="radio=='1'" label="跳转链接" prop="jumpUrl">
            <el-input
              v-model="ruleForm.jumpUrl"
              style="width: 500px"
              placeholder="请输入跳转链接"
            ></el-input>
          </el-form-item>
          <el-form-item v-show="radio!='1'" label="商品id" prop="relationId">
            <el-input
              v-model="ruleForm.relationId"
              style="width: 500px"
              placeholder="请输入商品id"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">立即修改</el-button>
            <el-button @click="$router.push('/carousel/list')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import url from "@/utils/file";
import { carouselEdit, carouselIdWord } from "@/api/carousel";
import { getStore } from "@/utils/storage";
export default {
  data() {
    const ID = (rule, value, callback) => {
        if(this.radio=='2'){
          if(value==""){
            callback('商品id不能为空')
          }else{
            callback()
          }
        }else{
          callback()
        }
    };
    const imgSrc = (rule, value, callback) => {
        if(this.radio=='1'){
          if(value==""){
            callback('链接地址不能为空')
          }else{
            callback()
          }
        }else{
          callback()
        }
    };
    return {
      radio:'',//单选
      ruleForm: {
        source:"",
        height: null, //权重
        jumpUrl: "", //跳转链接
        imgUrl: "", //图片地址
        title: "", //标题
        startTime: "", //开始时间
        endTime: "", //结束时间
        id: this.$route.query.roleid, //轮播图id
        relationId:""
      },
      rules: {
        title: [
          { required: true, message: "请输入轮播图标题内容", trigger: "blur" },
          {
            min: 2,
            max: 12,
            message: "长度在 2 到 12 个字符",
            trigger: "blur",
          },
        ],
        jumpUrl: [
          {
            validator: imgSrc,
            trigger: "blur"
          },
          {
            pattern:
              /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
            message: "请输入正确的链接地址",
            trigger: "change",
          },
        ],
        relationId:[
          {
            validator: ID,
            trigger: "blur"
          },
        ],
        height: [
          { pattern: /[0-9]/, message: "请输入数字类型", trigger: "blur" },
        ],
        startTime: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: "change"
          },
        ],
        imgUrl: [
          { required: true, message: "请选择轮播图图片", trigger: "change" },
        ],

      },
      dialogImageUrl: "",
      dialogVisible: false,
      ImageUrl: url.url_image, //上传地址
      fileList: [],
    };
  },

  created() {
    carouselIdWord({ id: this.$route.query.roleid }).then((res) => {
      let { height, jumpUrl, imgUrl, title, startTime, endTime,relationId,source} = res.data;
      this.ruleForm.height = height;
      this.ruleForm.jumpUrl = jumpUrl;
      this.ruleForm.imgUrl = imgUrl;
      this.ruleForm.title = title;
      this.ruleForm.startTime = startTime;
      this.ruleForm.endTime = endTime;
      this.fileList.push({ url: res.data.imgUrl });
      this.ruleForm.relationId=relationId
      this.ruleForm.source=source
      this.ruleForm.source=='1'? this.radio='1':this.radio='2'
    });
  },

  methods: {
    //单选
    dx(){
      this.ruleForm.source=this.radio
    },
    submitForm() {
      if(this.ruleForm.height<0){
        this.$notify({
                 title: '警告',
                 message: '权重不能小于0',
                 type: 'warning',
                 duration:1000
               });
               return
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          carouselEdit(this.ruleForm).then((res) => {
            if(res.status=='10000'){
                this.$router.push("/carousel/list");
            }
            this.fileList = [];
          });
        } else {
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      this.fileList = [];
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(file, fileList) {
      this.ruleForm.imgUrl = file.data; // 保存地址给变量
    },
  },
  computed: {
    startDatePicker() {
      return {
        disabledDate: (time) => {
          if (this.ruleForm.endTime) {
            return time.getTime() > new Date(this.ruleForm.endTime).getTime();
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
      };
    },
    
    endDatePicker() {
      return {
        disabledDate: (time) => {
          if (this.ruleForm.startTime) {
            return (
              time.getTime() <
              new Date(this.ruleForm.startTime).getTime() -
                0 * 24 * 60 * 60 * 1000
            );
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
      };
    },
    
    // 上传组件设置请求头
    headers() {
      return {
        Authorization: getStore("token"), // 直接从本地获取token就行
      };
    },
  },
};
</script>

<style>
</style>
